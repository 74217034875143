.study-layout {
  background-color: white;
  display: flex;
  justify-content: center;
  min-height: 125vh;
}

.study-container {
  width: 100%;
}

.sm-crumbs .sm-breadcrumb {
  margin-bottom: 0;
  margin-top: 0;
}

.sm-tabs > div {
  z-index: 2;
}

.sm-tabs > div {
  padding-left: 24px;
}

.study-container .sm-tabs::before {
  margin-bottom: 0;
}
