.App {
  text-align: center;
}

.flex {
  display: flex;
}

.flex-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.link {
  cursor: pointer;
}

.b-font {
  font-weight: 600 !important;
}

.p-relative {
  position: relative;
}

.flexWrap {
  flex-direction: column;
}

.left-align {
  align-items: flex-start;
}

* {
  box-sizing: border-box;
}

#topNavbar {
  top: 0;
  left: auto;
  right: 0;
  position: fixed;
  width: 100%;
  z-index: 998;
}

#topNavbar header div.MuiToolbar-regular {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.page-wrapper {
  background: #f6f7fb;
  min-height: 100vh;
  position: relative;
  padding-top: 56px;
}

.custom-checkbox {
  appearance: auto;
  background-color: initial;
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.Message-Banner {
  z-index: 1100 !important;
  top: 56px !important;
}

label.inline-checkbox {
  flex-direction: row-reverse;
  align-items: center;
}

label.inline-checkbox .MuiSwitch-sizeSmall .MuiSwitch-switchBase {
  top: -8px;
  left: -8px;
}

label.inline-checkbox > span:last-child {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

label.table-checkbox {
  flex-direction: row-reverse;
  align-items: center;
  margin-left: -11px;
}

label.table-checkbox span[class*='MuiSwitch-switchBase-'] {
  top: -8.5px;
  left: -8px;
}

label.table-checkbox span[class^='MuiSwitch-thumb-'] {
  color: #fff !important;
}

label.table-checkbox > span:last-child {
  margin: 0;
  padding: 0;
  margin-right: 10px;
}

label.table-checkbox :hover span[class^='MuiSwitch-thumb-'] {
  color: #ecf3ff !important;
}

.navigation-panel .waves {
  position: absolute;
  top: 0;
  width: 125px;
  right: 0;
  overflow: hidden;
}

.navigation-panel .waves img {
  transform: scaleX(-1);
}

.flexWrap .clickDisabled {
  cursor: text;
  pointer-events: none;
}

.navigation-panel > div {
  background: linear-gradient(to right, #250056, #0076ae);
}

.navigation-panel .MuiDrawer-paper {
  padding-top: 15px;
  border-right: 1px solid #fff;
}

.navigation-panel > div > button {
  border: 1px solid red;
  display: none !important;
}
/* 
.navigation-panel .MuiDrawer-paper div[class^='Blade-body-'] {
  padding: 0 19px !important;
}

.navigation-panel div > div[class^='Blade-expandPanel'] {
  display: none;
}

.navigation-panel .MuiDrawer-paper .Blade-body-8386 {
  padding: 0 15px !important;
} */

.navigation-panel .link {
  color: #ffffff;
}

.navigation-panel .close-panel {
  color: #ffffff;
  cursor: pointer;
  margin-left: 8px;
}

.navigation-panel button {
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  padding: 0 10px;
  font-weight: 400;
}

.navigation-panel button img {
  width: 24px;
  height: 24px;
  opacity: 0.7;
  border-radius: 2px;
  background: #ffffff;
  margin-right: 8px;
}

.navigation-panel button:hover {
  background-color: rgba(255, 255, 255, 0.24);
}

.navigation-panel button:hover img {
  opacity: 1;
}

ul[class*='MuiMenu-list-'] li:focus:not(:hover) {
  background: none;
}
