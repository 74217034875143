.banner-style {
  margin-top: 50px;
  padding: 1.5rem;
}

.heading {
  color: #fff !important;
}

.dashboard-space {
  min-height: 330px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-style {
  padding: 16px;
  text-align: center;
  height: 140px;
}

.cards-row .card-block {
  margin-right: 2rem;
  margin-bottom: 1rem;
  margin-top: 24px;
}

.cards-row .card-block:last-child {
  margin-right: 0px;
}

h5 {
  color: #706f6f;
  margin-top: 0rem;
}

.dashboard-wrapper {
  margin: 24px 0;
}
