.standard-library1 {
    background-color: #f5f6fa;
    display: flex;
    justify-content: center;
    /* min-height: 125vh; */
  }
  
  .standard-container {
    width: 100%;
  }
  