.tooltip {
  position: relative;
  display: inline-block;
}
.tooltip .tooltiptext {
  visibility: hidden;
  position: absolute;
  width: 230px;
  background-color: #444444;
  color: white !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.tooltip .tooltiptext2 {
  visibility: hidden;
  position: absolute;
  width: 120px;
  background-color: #444444;
  color: #fff !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
}
.tooltip:hover .tooltiptext2 {
  visibility: visible;
}

.tooltip .tooltiptext3 {
  visibility: hidden;
  position: absolute;
  font-size: small;
  width: 250px;
  background-color: #444444;
  color: white !important;
  text-align: center;
  padding: 5px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
}

.tooltip:hover .tooltiptext3 {
  visibility: visible;
  opacity: 1;
}

.tooltip .tooltiptext4 {
  visibility: hidden;
  position: absolute;
  font-size: small;
  width: 50px;
  background-color: #444444;
  color: white !important;
  text-align: center;
  padding: 2px 0;
  border-radius: 6px;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.6s;
}

.tooltip:hover .tooltiptext4 {
  visibility: visible;
  opacity: 1;
}

.tooltip-right {
  top: -5px;
  left: 125%;
}
.tooltip-right2 {
  top: -5px;
  left: 105%;
}

.tooltip-right::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #444444 transparent transparent;
}

.tooltip-bottom {
  top: 135%;
  left: 50%;
  margin-left: -115px;
}

.tooltip-bottom2 {
  top: 135%;
  left: 25%;
  margin-left: -60%;
}

.tooltip-bottom3 {
  top: 135%;
  left: 25%;
  margin-left: -40%;
}

.tooltip-bottom4 {
  top: 135%;
  left: 0%;
  margin-left: -50%;
}

.tooltip-bottom::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444444 transparent;
}

.tooltip-bottom2::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444444 transparent;
}

.tooltip-bottom3::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444444 transparent;
}

.tooltip-bottom4::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent #444444 transparent;
}

.tooltip-top {
  bottom: 125%;
  left: 50%;
  margin-left: -60px;
}
.tooltip-top2 {
  bottom: 115%;
  left: 50%;
  margin-left: -60px;
}

.tooltip-top::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #444444 transparent transparent transparent;
}

.tooltip-left {
  top: -5px;
  bottom: auto;
  right: 128%;
}

.tooltip-left2 {
  top: -5px;
  bottom: auto;
  right: 105%;
}

.tooltip-left::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent #444444;
}

.tooltip .tooltiptext-bottomarrow {
  visibility: hidden;
  width: 120px;
  background-color: #444444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  bottom: 130%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext-bottomarrow::after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: black transparent transparent transparent;
}
.tooltip:hover .tooltiptext-bottomarrow {
  visibility: visible;
}

.tooltip .tooltiptext-toparrow {
  visibility: hidden;
  width: 120px;
  background-color: #444444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: 150%;
  left: 50%;
  margin-left: -60px;
}
.tooltip .tooltiptext-toparrow::after {
  content: '';
  position: absolute;
  bottom: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent black transparent;
}
.tooltip:hover .tooltiptext-toparrow {
  visibility: visible;
}

.tooltip .tooltiptext-leftarrow {
  visibility: hidden;
  width: 120px;
  background-color: #444444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
}
.tooltip .tooltiptext-leftarrow::after {
  content: '';
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent black transparent transparent;
}
.tooltip:hover .tooltiptext-leftarrow {
  visibility: visible;
}
.tooltip .tooltiptext-rightarrow {
  visibility: hidden;
  width: 120px;
  background-color: #444444;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  right: 110%;
}
.tooltip .tooltiptext-rightarrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent transparent black;
}
.tooltip:hover .tooltiptext-rightarrow {
  visibility: visible;
}
