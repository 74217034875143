.db_domain_preview label{
    display: none;
}

.db_domain_preview label + div{
    margin-top:0px;
}

.checkbox_domain_preview svg{
    width: 20px;
    height: 20px;
    color: #827777;
}

.test_domain_preview{
    height: 35px !important;
    padding: 0 25px !important;
    font-size: 14px !important;
}